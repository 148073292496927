*{
    margin: 0;
    padding: 0;
}
.fixed-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }